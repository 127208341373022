/**
 * 字符串加密传输
 * 说明:
 * 1. key, iv值由后台提供
 */
import CryptoJS from 'crypto-js'
export const encrypt = src => {
  let key = '212a265e6c66636f6d6d6f6e63686174'
  key = CryptoJS.enc.Hex.parse(key)
  const iv = CryptoJS.enc.Hex.parse('30313233343536373839414243444546')
  const enc = CryptoJS.AES.encrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const enced = enc.ciphertext.toString()
  // console.warn('加密:', enced)
  return enced
}

export const decrypt = src => {
  let key = '212a265e6c66636f6d6d6f6e63686174'
  key = CryptoJS.enc.Hex.parse(key)
  const iv = CryptoJS.enc.Hex.parse('30313233343536373839414243444546')
  const dec = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(src), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  // console.log('解密:', CryptoJS.enc.Utf8.stringify(dec))
  return CryptoJS.enc.Utf8.stringify(dec)
}
